// import lek from '@/pages/lek/index.vue';
import { defineComponent } from "vue";
import { getHomePageBackground, getPageMessage, getAdImgAndVideo } from "@/api";
import { useAppStore } from "@/store";
import { Navigation, Pagination, A11y, Autoplay, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
// import "pid/index.vue";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import cookies from 'vue-cookies';
const store = useAppStore();
const cookies_ = cookies;
export default defineComponent({
  components: {
    // lek
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      store_: store,
      backgroungImg: [],
      video: "",
      introduce: [],
      introduceMsg: "",
      news: [],
      roundBox: [],
      friends: [],
      isShow: 0,
      phoneBanner: "",
      claim: "",
      fontclaim: "",
      modules: [Navigation, Pagination, A11y, Autoplay, Zoom],
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      is_CN: true
    };
  },
  async created() {
    const lang = cookies_.get('system_lang');
    if (lang != 'cn') this.is_CN = false;
    try {
      // this.$router.push({name:'tools'})
      this.isShow = document.documentElement.clientWidth;
      // this.$store.getters.getDemo
      const news = await getPageMessage(store.getPageID('news'));
      this.news = news.data;
      const introduce = await getPageMessage(store.getPageID('introduce'));
      this.introduce = introduce.data;
      this.introduceMsg = introduce.data.content_list[0].intro;
      const friends = await getPageMessage(store.getPageID('friends'));
      this.friends = friends.data;
      const roundBox = await getPageMessage(store.getPageID('roundBox'));
      this.roundBox = roundBox.data.content_list;
      this.getContent();
      // this.getNews()
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    gotolink() {
      this.$router.push("/pid");
      let data = {
        // query: { pathId: store.getPageID('photoID'), name: store.homeMode('photoID') },
        query: {
          pathId: store.getPageID('photoID'),
          name: store.homeMode('photoID'),
          listType: '1',
          adShow: '1',
          adNum: '25'
        }
      };
      store.setRouter(data);
    },
    async getContent() {
      try {
        const res = await getHomePageBackground({
          cid: 9,
          pid: store.getPageID('home')
        });
        // let length = (res as any).data.ad_list.length - 1
        this.video = res.data.ad_list[0].pic;
        let cc = res.data.ad_list.map(item => {
          let name = item.jump_link.split("_")[6];
          return {
            item,
            name: name
          };
        });
        // cc.splice(length, 1)
        this.backgroungImg = cc;
      } catch (e) {
        console.error(e);
      }
      const src = await getAdImgAndVideo(11);
      this.phoneBanner = src.data.ad_list[0].pic;
      const img = await getAdImgAndVideo(33);
      this.claim = img.data.ad_list[0].pic;
      // const font = await getAdImgAndVideo(0);
      // this.fontclaim = (font as any).data.title;
    },

    // async getNews(){
    // }
    toModelPageList() {
      let ccp = Math.round(Math.random() * 10000);
      // let cc = this.news
      let data = {
        query: {
          pathId: store.getPageID('news'),
          listType: 2
        }
      };
      this.$router.push({
        path: "/modelPageList",
        query: data.query
      });
      store.setRouter(data);
    },
    // button(){
    // 	this.$router.push({path:"/pid"});
    // 	let data = {pathId:106};
    // 	store.setRouter(data);
    // },
    toRichText(item, index) {
      //   let data = {
      //     query: { pathId: 46, name: item.title, adShow: item.has_ad, adNum: 18,ppid:index},
      //   };
      let data = {
        query: {
          pathId: store.getPageID('news'),
          ppid: index
        }
      };
      if (item.is_blank == 1) {
        const url = this.$router.resolve({
          path: "/richText",
          query: data.query
        });
        window.open(url.href);
      } else {
        this.$router.push({
          path: "/richText",
          query: data.query
        });
        store.setRouter(data);
      }
    },
    yahu1(ee) {
      console.debug();
    },
    yahu(ee) {
      console.debug();
    },
    jump(item, index, setTopColor) {
      let linkMsg = item.jump_link.split("_"); //父项
      let cc = Math.round(Math.random() * 10000); //获得一个随机数，用于延展页面的watch检测
      let dataType0 = {
        //dataType0用法是点击导航栏父项跳转使用，会跳转至该父项下第一个子项（主要由后台配置）
        querys: {
          //这里的数据的来自后台对应页面的栏目链接上，由'_'分割，对应数据的排列顺序是  页面ID 页面名称 模板类型ID 广告控制 广告ID
          pathId: linkMsg[1],
          // name: linkMsg[2],
          listType: linkMsg[3]
        }
      };
      store.setRouter(dataType0);
      const url = this.$router.resolve({
        path: linkMsg[0],
        query: dataType0.querys
      });
      window.open(url.href);
      if (setTopColor == 1) {
        store.setTopBarIndex(index);
      }
    },
    go() {
      console.log(1);
    }
  },
  watch: {
    '$window.href': 'go'
  }
});