import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue";
const _hoisted_1 = {
  class: "home XhorizontalCenter"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 1,
  class: "box"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "buttonBox XhorizontalCenter"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "mainImg"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "home_boxs Allmiddle"
};
const _hoisted_10 = {
  class: "margin-left-128 roundBoxs"
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = ["src"];
const _hoisted_13 = {
  class: "home_likeBox"
};
const _hoisted_14 = {
  class: "home_likeBox_title Allmiddle"
};
const _hoisted_15 = {
  class: "fontStyleForPageTitle big"
};
const _hoisted_16 = {
  class: "newMessages Allmiddle"
};
const _hoisted_17 = {
  class: "mediaBox"
};
const _hoisted_18 = ["onClick"];
const _hoisted_19 = ["src"];
const _hoisted_20 = {
  class: "newMessages_box_text"
};
const _hoisted_21 = {
  class: "speciesClaimed"
};
const _hoisted_22 = {
  class: "font"
};
const _hoisted_23 = {
  class: "claim1"
};
const _hoisted_24 = ["src"];
const _hoisted_25 = {
  class: "cc Allmiddle"
};
const _hoisted_26 = {
  class: "home_introduce XhorizontalCenter"
};
const _hoisted_27 = {
  class: "home_introduce_title"
};
const _hoisted_28 = {
  class: "big fontStyleForPageTitle"
};
const _hoisted_29 = {
  class: "home_introduce_text"
};
const _hoisted_30 = {
  class: "flexX home_introduce_imgBoxs"
};
const _hoisted_31 = ["src"];
const _hoisted_32 = {
  class: "home_cooperation Allmiddle"
};
const _hoisted_33 = {
  class: "fontStyleForPageTitle big"
};
const _hoisted_34 = {
  class: "home_cooperation_imgBox"
};
const _hoisted_35 = ["href"];
const _hoisted_36 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_swiper, {
    class: "swipers",
    modules: _ctx.modules,
    "slides-per-view": 1,
    initialSlide: 7,
    speed: 1500,
    "space-between": 50,
    navigation: "",
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      clickable: true
    },
    scrollbar: {
      draggable: true
    },
    onSwiper: _ctx.yahu,
    onSlideChange: _ctx.yahu1
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.backgroungImg, (item, index) => {
      return _openBlock(), _createBlock(_component_swiper_slide, {
        class: _normalizeClass(["wh", {
          'd-none d-lg-block': index == 0
        }]),
        key: index
      }, {
        default: _withCtx(() => [index == 0 ? (_openBlock(), _createElementBlock("video", {
          key: 0,
          class: "videos",
          autoplay: "true",
          preload: "auto",
          loop: "true",
          muted: "true",
          "x-webkit-airplay": "true",
          "x5-playsinline": "true",
          "webkit-playsinline": "true",
          src: _ctx.video,
          playsinline: "true"
        }, null, 8, _hoisted_2)) : _createCommentVNode("", true), index != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("img", {
          src: item.item.pic
        }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [_createElementVNode("button", {
          class: "jumpButton",
          onClick: $event => _ctx.jump(item.item, index, 1)
        }, _toDisplayString(item.name), 9, _hoisted_6)])])) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["class"]);
    }), 128))]),
    _: 1
  }, 8, ["modules", "onSwiper", "onSlideChange"]), _createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
    src: _ctx.phoneBanner
  }, null, 8, _hoisted_8)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roundBox, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      onClick: $event => _ctx.jump(item, index, 0),
      class: "home_boxs_box XhorizontalCenter"
    }, [_createElementVNode("img", {
      src: item.litpic
    }, null, 8, _hoisted_12), _createElementVNode("span", null, _toDisplayString(item.title), 1)], 8, _hoisted_11);
  }), 128))])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.news.name), 1), _createElementVNode("p", {
    class: "fontSize28 cursorPointer",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.toModelPageList())
  }, _toDisplayString(_ctx.store_.homeMode('more')) + ">>", 1)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news.content_list, (item, index) => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "newMessages_box XhorizontalCenter",
      key: index,
      onClick: $event => _ctx.toRichText(item, index)
    }, [_createElementVNode("img", {
      src: item.litpic,
      class: "newMessages_box_img"
    }, null, 8, _hoisted_19), _createElementVNode("div", _hoisted_20, _toDisplayString(item.title), 1)], 8, _hoisted_18)), [[_vShow, index <= 2]]);
  }), 128))])])]), _createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.store_.homeMode('adopt')), 1), _createElementVNode("div", _hoisted_23, [_createElementVNode("img", {
    src: _ctx.claim
  }, null, 8, _hoisted_24), _createElementVNode("div", _hoisted_25, [_createElementVNode("button", {
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.gotolink && _ctx.gotolink(...args))
  }, _toDisplayString(_ctx.store_.homeMode('wantAdopt')), 1)])])]), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.introduce.name), 1)]), _createElementVNode("div", _hoisted_29, "   " + _toDisplayString(_ctx.introduceMsg), 1), _createElementVNode("div", _hoisted_30, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.introduce.content_list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "imgBox",
      key: index
    }, [_createElementVNode("img", {
      src: item.litpic
    }, null, 8, _hoisted_31)]);
  }), 128))])]), _createElementVNode("div", _hoisted_32, [_createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.friends.name), 1), _createElementVNode("div", _hoisted_34, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.friends.content_list, (item, index) => {
    return _openBlock(), _createElementBlock("a", {
      target: "_blank",
      style: {
        "margin-top": "19px"
      },
      key: index,
      href: item.jump_link
    }, [_createVNode(_Transition, {
      mode: "in-out"
    }, {
      default: _withCtx(() => [_createElementVNode("img", {
        class: _normalizeClass(index != 3 && index != 7 ? 'ccright66' : ''),
        src: item.litpic
      }, null, 10, _hoisted_36)]),
      _: 2
    }, 1024)], 8, _hoisted_35);
  }), 128))])])]);
}